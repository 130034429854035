<!-- src/components/Password.vue -->

<template src="@/../../../templates/brand/components/templates/user/Password.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import auth from '@/util/auth'
import { Profile } from '@/models/ProfileModel'

export default {
  created: function () {
    this.userDetails = new Profile(this.user.profile)
  },
  data () {
    return {
      oldPass: '',
      newPass: '',
      confirmPass: '',
      oldPassInvalid: true,
      editPass: false,
      editProfile: false,
      editRec: { first: '', last: '', address: {} },
      userDetails: {}
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    userProfile: function () {
      return this.user.profile
    },
    passCanChange: function () {
      return (
        !this.oldPassInvalid &&
        this.oldPass.length &&
        this.newPass.length &&
        this.newPass === this.confirmPass
      )
    }
  },
  methods: {
    ...mapActions(['profileUpdate']),
    updateProfile: function () {
      // console.info('update with',this.userDetails.getData(),this.user.profile)
      const _profile = _.assign(
        _.assign({}, this.user.profile),
        this.userDetails.getData()
      )
      // Validate...
      this.profileUpdate({ user: this.user, profile: _profile })
      this.editProfile = false
    },
    changePass: function () {
      if (!this.oldPassInvalid && this.newPass === this.confirmPass) {
        this.$http
          .post('/users/changepass', { from: this.oldPass, to: this.newPass })
          .then(() => {
            this.logout()
          })
          .catch(err => {
            console.error(' :: error changing password', err)
          })
      }
    },
    testOldPass () {
      auth.testPass(this, this.oldPass).then(res => {
        return (this.oldPassInvalid = res.pass)
      })
    }
  },
  events: {
    // 'menu.select': function(){ console.log('event on child ', arguments) }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Password.css"></style>
